@import '../../sassStyles/global-imports';

// Table styles

.table-ctr {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;

  @include smMinBreakPoint {
    display: block;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
  }

  @include xlMinBreakPoint {
    display: table;
  }


  thead {
    display: none;

    @include smMinBreakPoint {
      display: table-header-group;
    }
  }

  tr {
    margin-bottom: 1.5rem;
    display: block;
    border-bottom: 1px solid $color-greyishblue-light;

    @include smMinBreakPoint {
      margin-bottom: initial;
      display: table-row;
      border-bottom: 0;
    }
  }

  tr th,
  tr td {
    @include smMinBreakPoint {
      border-right: 1px solid $color-greyishblue-light;
      border-bottom: 1px solid $color-greyishblue-light;
      padding: 1rem;
    }
  }

  tr th:first-child,
  tr td:first-child {
    @include smMinBreakPoint {
      border-left: 1px solid $color-greyishblue-light;
    }
  }

  tr {
    border: 1px solid $color-greyishblue-light;

    @include smMinBreakPoint {
      border: 0;
    }
  }

  tr th {
    @include smMinBreakPoint {
      background: $color-greyishblue;
      border-top: 1px solid $color-greyishblue-light;
      text-align: left;
    }
  }

  tr:hover {
    background-color: $color-greyishblue;
  }

  th {
    font-size: $body-sm;
    color: $color-darkblue;
    font-weight: $regular;
  }

  td {
    font-size: $body-sm;
    color: $color-darkgrey;
    font-weight: $light;
    display: block;
    text-align: right;
    border-bottom: 1px solid $color-greyishblue-light;
    padding: 1rem;

    @include smMinBreakPoint {
      display: table-cell;
      text-align: left;
    }
  }

  td:last-child {
    border-bottom: 0;
    @include smMinBreakPoint {
      border-bottom: 1px solid $color-greyishblue-light;
    }
  }

  td:before {
    content: attr(data-label);
    float: left;
    font-size: $body-sm;
    color: $color-darkblue;
    font-weight: $regular;

    @include smMinBreakPoint {
      content: initial;
      float: initial;
    }
  }

  /* top-left border-radius */
  tr:first-child th:first-child {
    border-top-left-radius: 0;

    @include smMinBreakPoint {
      border-top-left-radius: 6px;
    }
  }

  /* top-right border-radius */
  tr:first-child th:last-child {
    border-top-right-radius: 0;

    @include smMinBreakPoint {
      border-top-right-radius: 6px;
    }
  }

  /* bottom-left border-radius */
  tr:last-child td:first-child {
    border-bottom-left-radius: 0;

    @include smMinBreakPoint {
      border-bottom-left-radius: 6px;
    }
  }

  /* bottom-right border-radius */
  tr:last-child td:last-child {
    border-bottom-right-radius: 0;

    @include smMinBreakPoint {
      border-bottom-right-radius: 6px;
    }
  }
}

