@import '../../sassStyles/global-imports';

.navbar {
  background-color: $accent;
  border-bottom: 1px solid $color-greyishblue-light;

  .navbar-nav {
    vertical-align: middle;
    align-items: center;

    @include mdMinBreakPoint {
      align-items: initial;
    }
  }

  .nav-logo {
    height: 18px;

      @include smMinBreakPoint {
       height: 23px;
      }
    }

  p, a {
    color: $color-primary-blue;
    font-size: 0.875em;
    padding: 1em;


    @include mdMinBreakPoint {
      padding: 0.5em 0;
    }
  }

  p {
    margin-bottom: 0;

    @include mdMinBreakPoint {
      margin-right: 2em;
    }
  }

  .icon { //used a lot, how to make global?
    width: 1.2em !important;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0.2em;

    @include mdMinBreakPoint {
      width: 1.2em !important;
      margin-right: 0.3em;
    }
  }
}