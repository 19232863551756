@import '../../sassStyles/global-imports';

.container-404 {
  display:flex;
  justify-content: center;

 .row .col img {
  padding-right: 0;
   width: 150px;
 }

 .row .col:nth-child(2) {
  padding-top: 3rem;
}

 .row .col:nth-child(2) h1 {
  color: $color-darkgrey;
  font-size: $heading;
  padding-bottom: 0;
  margin-bottom: -0.5rem;
  padding-top: 0;
}

 .row .col:nth-child(2) p {
  color: #363636;
  font-size: $body-lg;
}

  @include smMinBreakPoint {

    .row .col img {
      width: 200px;
    }
    .row .col:nth-child(2) p {
      font-size: 1rem;
    }
  }

  @include mdMinBreakPoint {
    padding-top: 10rem;

    .row .col img {
      padding-top: 2rem;
    }
    .row .col:nth-child(2) h1 {
      font-size: 6rem;
    }
  }
}